import "core-js/stable";
import "regenerator-runtime/runtime";

import Cookies from 'js-cookie'
import ImageOptimizer from './image_optimizer'
// import Stats from './stats';
import I18n from "../i18n"
import User from "../user"

// Rest of your application code
const user = new User();
if (!Cookies) {
  Cookies = {
    get: function(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    set: function(name, value, options = {}) {
      let cookieString = `${name}=${value}; path=/`;
      if (options.expires) {
        const date = new Date();
        date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000));
        cookieString += `; expires=${date.toUTCString()}`;
      }
      document.cookie = cookieString;
    }
  };
}

window.ck = Cookies;

let debugMode = false;
const debugCookie = Cookies.get('ldbg');
if (debugCookie && debugCookie === "true") {
  debugMode = true;
}

const i18n = window.I18n = window.i18n || I18n(Cookies, user, debugMode);

if (debugMode) {
  console.debug("[i18n.js] translations have arrived", i18n)
}

ImageOptimizer()
// const stats = new Stats();

// check for GDPR
if (Cookies.get('cookie_accepted')) {
  console.log("[cookie-policy.js] ahoy init")
  import('services/ahoy')
} else {
  window.cookiePolicyInit = async function (init) {
    init({ i18n: i18n })
  };
  console.log("[cookie-policy.js] loading")
  import('components/cookie-policy')
}

import('components/cookie-policy')
//window.onerror = function(evt) {
  //console.debug('[application] onerror caught', evt)
//}