import { I18n } from "i18n-js";

const LOCALES = [
  "de", "el", "en", "es", "fa", "fr", "he", "it", "ja", "ko", "nl", "pl", "pt-PT", "ru", "zh-CN"
]

// manually updated, used as a fallback
const DEFAULT_TRANSLATIONS = {}
let I18N_DEBUG = false;

export default function createI18nInstance(cookieStore, user, debugI18n = false) {

  if (debugI18n) {
    console.log("[i18n.js] Debug mode enabled")
    I18N_DEBUG = true;
  }

  const defaultLocale = "en"
  let localeCookie;
  let chosenLocale = defaultLocale;
  
  try {
    if (cookieStore && typeof cookieStore.store == "function") {
      localeCookie = cookieStore ? (cookieStore.getCookie || cookieStore)("locale") : null;
    } else if (cookieStore && typeof cookieStore.store == "object") {
      localeCookie = cookieStore.store.locale;
    } else if (cookieStore && typeof cookieStore == "function") {
      localeCookie = cookieStore("locale");
    } else {
      localeCookie = null;
    }
    
    if (!localeCookie) {
      localeCookie = cookieStore ? (cookieStore.getCookie || cookieStore)("locale") : null;
    }

  } catch (error) {
    console.debug('[i18n] localeCookie not used', error)
  }
  

  if (localeCookie) {
    if (LOCALES.includes(localeCookie)) {
      chosenLocale = localeCookie
    } else {
      if (I18N_DEBUG) console.error("[i18n.js] Unknown locale from cookie: " + localeCookie)
    }
  }

  const browserLocale = navigator.language || navigator.userLanguage
  if (browserLocale && LOCALES.includes(browserLocale)) {
    chosenLocale = browserLocale
    if (I18N_DEBUG) console.log("[i18n.js] locale from browser:", chosenLocale)
  }

  // if the current url has the format "/:locale/", use that as the default
  const initialPath = window.location.pathname.split("/")[1]
  if (LOCALES.includes(initialPath)) {
    if (I18N_DEBUG) console.log("[i18n.js] locale from URL: " + initialPath)
    chosenLocale = initialPath
  }

  if (user && user.locale) {
    if (LOCALES.includes(user.locale)) {
      chosenLocale = user.locale
    } else {
      if (I18N_DEBUG) console.error("[i18n.js] Unknown locale: " + user.locale)
    }
  }

  let translations = window.I18n && window.I18n.translations
  if (!translations) {
    translations = window.tx_localized || DEFAULT_TRANSLATIONS
    if (I18N_DEBUG) console.error("[i18n.js] No translations found, using default", translations)
  } else {
    if (I18N_DEBUG) console.debug("[i18n.js] using translations", translations)
  }

  const i18n = new I18n(translations, { locale: chosenLocale });
  const i18nStandin = {
    custom: true,
    t: (k, opts = {}) => {
      let l = (opts && opts.locale) || chosenLocale;
      if (l != chosenLocale) {
        if (I18N_DEBUG) console.error("[i18n.js] Locale changed from " + chosenLocale + " to " + opts.locale);
      }
      
      // Extract interpolation values and locale from opts
      const { locale, ...interpolations } = opts;
      
      // Log the key, locale, and interpolations
      if (I18N_DEBUG) {
        console.log(`[i18n.js] Translating key: ${k}, Locale: ${l}, Interpolations:`, interpolations);
      }

      // Use i18n.t with both the key and interpolations
      const translation = i18n.t(k, { 
        locale: l || defaultLocale,
        ...interpolations
      });

      // Log the translation result
      if (I18N_DEBUG) console.log(`[i18n.js] Translation result for key: ${k}, Locale: ${l}, Result: ${translation}`);

      return translation;
    }
  };

  return i18nStandin
}

// Example usage
const i18nInstance = createI18nInstance(null, { locale: 'en' }, I18N_DEBUG);
window.i18n = i18nInstance
console.log(i18nInstance.t("event_submissions.add_photos.image_uploader.benefit_html", { amount: 35 }));